.detail-weergave
  @include user-select(none)
  +span-columns(11.5)
  +shift(.25)
  +box-shadow
  background-color: $wit
  margin-top: 1px
  max-height: 0em
  visibility: hidden
  overflow: hidden
  +transition(.15s ease)
  +clearfix
  > header, section, footer
    +span-columns(11)
    +shift(.5)
    +clearfix
    +position(relative)
    color: darken($donkergrijs, 10%)
  > header
    height: $bestelregelHoogte
    border-bottom: 2px solid $grijs
    line-height: $bestelregelHoogte
    > h4
      +span-columns(6)
    > .buttons
      +span-columns(6)
      text-align: right
  > section
    padding-top: 1em
    .right, .left
      +span-columns(6)
      > section, > .farmaca section
        padding-top: 0em
        word-wrap: break-word
        margin-left: 0em
        line-height: 2

      > .farmaca
        section, &:after
          line-height: 1em

          &:first-child
            margin-top: .25em

        &:after
          content: ' '
          position: relative
          width: 100%
          float: left
          border-bottom: 1px solid #eee
          padding-bottom: .25em

      > h4
        margin-top: 1.4em
        +span-columns(12)
      > .labels
        +span-columns(4)
      > .data
        +span-columns(6)
      > .opmerkingen
        +span-columns(12)
    .detail
      +span-columns(6)
      font-size: .9em

  > footer
    height: $bestelregelHoogte
  &.show-details
    visibility: visible
    +transition(.45s ease)
    max-height: $bestelregelHoogte * 30
    border-bottom: 0px $background solid
    margin-bottom: 15px

  .niet-verwerkt-melding

    border: 2px rgba(226, 19, 19, 0.60) solid
    border-radius: 3px
    background-color: rgba(226, 19, 19, 0.20)
    padding: .5em
    margin-top: 1em
    color: $bodyTextColor

    h4
      color: $bodyTextColor
      margin-bottom: .2em

  .detail-bestand
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    > a
      cursor: pointer