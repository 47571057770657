button:after
  width: 0
  @include transition-property(visibility)
  @include transition-duration(.2s)
  @include transition-timing-function(ease-in)

.loading
  .loading-spinner-blue, .loading-spinner-white
    background-image: url(../images/ajax-loader-blue.gif)
    background-repeat: no-repeat
    background-position-x: center
    background-position-y: center

  .loading-spinner-white
    background-image: url(../images/ajax-loader-white.gif)

  button
    &.loading-spinner-white, &.loading-spinner-blue
      padding-right: 35px
      background-image: none
      &:after
        content: ''
        width: 16px
        height: 16px
        background-image: url(../images/ajax-loader-blue.gif)
        background-repeat: no-repeat
        background-position-x: center
        background-position-y: center
        position: absolute
        margin-left: 10px

    &.loading-spinner-white
      background-image: none
      &:after
        background-image: url(../images/ajax-loader-white.gif)

.laad-regel
  background-image: url(../images/ajax-loader-blue.gif)
  background-repeat: no-repeat
  background-position-x: center
  background-position-y: center
  height: $bestelregelHoogte !important

.geen-resultaten
  text-align: center

#bestel-modal footer .primary-button.loading-spinner-white:after
  margin-left: 36px
  margin-top: -16px
