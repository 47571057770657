
#tab-bar
  +fill-parent
  +position(absolute)
  top: $titleBarHeight +1
  background-color: $tabBarBackground
  font-size: 0.9em

  min-height: $tabBarHeight + $standardBorder
  li
    +span-columns(1.5)
    +omega
    list-style: none
    text-align: center
    line-height: $tabBarHeight + $standardBorder

    &.active
      color: $wit
      background-color: $ge-blauw
    &:hover
      color: $wit
      background-color: $ge-blauw

      +transition(all 0.2s ease-in-out)