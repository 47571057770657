//Alle koppen
h1, h2, h3, h4, h5
  text-transform: uppercase
  color: $ge-blauw

#artsenverklaring,
#artsenverklaring2020,
#artsenverklaring2022
  h1, h2, h3, h4, h5
    text-transform: none

h1
  font-weight: 300

h2
  font-weight: 400

h3
  font-weight: 400

h4
  font-weight: 400

h5
  font-weight: 500
  font-size: 0.55em

.floatright
  float: right

// Alle links
a
  &, a:visited
    color: darken($ge-blauw, 40%)
    text-decoration: underline
  &:hover
    color: $ge-blauw

//Alle soorten buttons (kleuren)

button, a.button
  +button
  &.primary-button
    background-color: $primaryButtonBGColor
    border-color: $primaryButtonBorderColor
    +click-glow($ge-blauw)
  &.secondary-button
    color: $secondaryButtonColor
    background-color: $secondaryButtonBGColor
    border-color: $secondaryButtonBorderColor
    +click-glow($donkergrijs)
  &.login-button
    color: $wit
    background-color: $ge-blauw
    border-color: $wit
  &.filter-button
    color: $secondaryButtonColor
    background-color: $secondaryButtonBGColor
    border-color: $secondaryButtonBorderColor
    //+click-glow($ge-blauw)
    line-height: $selectedFilterHeight
  &.action-primary-button
    background-color: $primaryButtonBGColor
    border-color: $primaryButtonBorderColor
    +click-glow($ge-blauw)
  &.action-secondary-button
    color: $toolBarButtonColor
    background-color: $secondaryButtonBGColor
    border-color: $toolBarButtonBGcolor
    +click-glow($ge-blauw)
  &.titlebar-button
    text-transform: uppercase
    color: $titleBarButtonColor
    background-color: $titleBarBackground
    border: none
    border-radius: 0
    font-size: 1em
    padding: 0 .8em 0 .8em
    line-height: $titleBarHeight
    max-height: $titleBarHeight
    height: $titleBarHeight
    &:hover
      background-color: $ge-blauw
      color: white
  &.wizardPrevious
    float: left

  &:disabled
    background-color: $grijs
    border-color: $grijs

a
  &.wizardCancel
    color: $ge-blauw
    padding-right: 10px
    text-decoration: underline

.invisible
  visibility: hidden

.warning
  color: $oranje

.success
  color: $success

.error
  color: $error

*[ui-sref], *[ng-click], *[data-ui-sref], *[data-ng-click]
  cursor: pointer

html
  background-image: url('../images/backgroundv1.jpg')
  background-repeat: no-repeat
  background-size: cover
  width: 100%
  height: 100%
  &:before
    position: absolute
    content: ''
    background-color: rgba(255, 255, 255, 0.6)
    top: 0
    bottom: 0
    left: 0
    right: 0

