=button
  +padding(0.5rem 1rem 0.5rem 1rem)
  +transition(0.25s)
  $button-shadow-amount: 5%
  border-radius: $borderRadiusStandard
  color: $wit
  font-size: 0.95rem
  font-weight: 400
  border: $standardBorder solid #000
  cursor: pointer
  line-height: 1.2

  &:focus
    outline: 0

=click-glow($color)
  &:active
    +transition(0)
    +no-shadow
    +transform(translateY(0))
    +padding(-1.8em)
    background-color: lighten($color, 8%)
//+box-sizing(border-box)


//shadows
=box-shadow
  box-shadow: 0 -1px 0 #e5e5e5, 0 0 1px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)
  -webkit-box-shadow: 0 -1px 0 #e5e5e5, 0 0 1px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)
  -moz-box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24)

=shadow-bottom
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28)
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28)
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28)

=shadow-bottom-extra
  box-shadow: 0 0 8px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28)
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28), 0 -4px 12px rgba(0, 0, 0, .28),
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28)
//border-bottom: 1px solid rgba(0, 0, 0, .28)

=no-shadow
  box-shadow: none
  -webkit-box-shadow: none
  -moz-box-shadow: none

=top-inset-shadow
  box-shadow: 0 -1px 0 #e5e5e5, 0 0 1px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24), inset -0px 42px 6px -42px rgba(0, 0, 0, 0.28)
  -webkit-box-shadow: 0 -1px 0 #e5e5e5, 0 0 1px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24), inset -0px 42px 6px -42px rgba(0, 0, 0, 0.28)
  -moz-box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24), inset -0px 42px 6px -42px rgba(0, 0, 0, 0.28)

=checkBoxFancy($iconColor, $textColor, $size)
  input[type=checkbox]
    display: none
  + label
    +transition(0.20s)
    cursor: pointer
    color: $iconColor

  //$iconSize
  > input[type=checkbox]:checked + label
    +transition(0.20s)
    color: $textColor
    font-size: $size
  > input[type=checkbox] + label
    font-size: $size
    vertical-align: middle

=standardInputStyling
  padding: 0 $paddingLeftRightInput
  font-size: 1em
  height: $inputHeight
  border: 0
  border: $standardBorder solid $standardFormBorderColor
  border-radius: $borderRadiusStandard
  &:focus
    +transition(0.25s)
    outline: none
    border-color: $standardFormBorderColorActive
