@import localMixins

.list-view, .list-heading
  font-weight: 400
  +media($mobile)
    +position(relative)
    top: $headerHeight +230px
  +position(absolute)
  +media($compensateFilterBarWidth)
    top: $headerHeight + 130px
  top: $headerHeight + 84px
  bottom: $footerHeight
  overflow: scroll

  > .headings
    +pad(30px)
  > .list
    padding: 0px 30px 30px 30px

  > .list, .headings
    +outer-container
    //background-color: $wit
    border-radius: 0px
    li
      +listItemStandardStyle

      +listItemWarningClasses
      //headings
      &.heading
        background-color: $ge-blauw
        color: $wit
        border-bottom: 0px solid $donkergrijs
        height: $bestelregelHoogte
        +box-shadow
        > .column, > div > .column
          border-right: none
          &.double .bottom
            color: $wit

        &:hover
          //Geen hover color op de headings nodig
          background-color: $ge-blauw
          +box-shadow

        > .checkbox-placeholder
          +span-columns(0.5)
          +padding(0.2em 0em 0.2em 0em)
          +margin(0em 0em 0em 0.35em)
          font-size: 1.8rem

      //checkbox accorderen

      input[type=checkbox]
        display: none

        + label
          +padding(0.35em 0em 0em 0em)
          +margin(0em 0em 0em 0em)
          +transition(0.20s)
          cursor: pointer
          color: $iconColor
          font-size: $iconSize
        &:checked + label, &.checked + label
          color: $ge-blauw

      //columns
      .column
        +span-columns($columnDefault)
        +ellipsis
        border-right: $background 1px solid
        height: $bestelregelHoogte - 2
        &.double
          vertical-align: middle
          line-height: $bestelregelHoogte / 2
          +span-columns(2)
        > .top
          +ellipsis
          +span-columns(12)

        > .bottom
          +ellipsis
          color: $ge-blauw
          +span-columns(12)
        > .fa-exclamation-triangle
          color: $error

        //Bevat de classes die gebruikt kunnen worden voor column sizing in de grid, algemeen en specifiek
        +listItemColumnClasses

      .icon
        +span-columns(0.5)
        color: $iconColor
        font-size: $iconSize
        line-height: $bestelregelHoogte

        &.fa-file-text-o
          color: $ge-blauw
        &.warning
          color: $oranje
        &.progress-verklaring
          color: $toolBarBackground
        &.akkoord-verklaring
          color: $ge-blauw
        &.warning-verklaring
          color: $oranje

        &.new-messages
          color: $ge-blauw
          &:after
            content: attr(data-aantal-ongelezen)
            color: $ge-blauw
            font-size: 0.35em
            position: absolute
            font-weight: bold
            margin-left: -2.7em
            margin-top: -0.25em
            width: 2em
            text-align: center

        &.active
          color: $ge-blauw
        &.hide
          visibility: hidden
      &:hover
        background-color: lighten($donkergrijs, 45%)
        +transition(.25s ease)
  .aantal-farmaca
    text-align: center
    width: 1.2em
    line-height: 1.2em
    background-color: $ge-blauw
    display: inline-block
    font-size: 1em
    border-radius: 1.5em
    color: white
    margin-left: .1em
    padding: 1px 2px

.list-heading

  font-weight: 400
  +media($mobile)
    +position(relative)
    top: $headerHeight +160px
  +position(absolute)
  +media($compensateFilterBarWidth)
    top: $headerHeight + 46px
  top: $headerHeight
  bottom: $toolbarHeight
  left: 0em
  right: -20px
  .headings
    .heading
      line-height: $bestelregelHoogte / 2

.list-view
  right: 0
  left: 20px

.bestelregelAttachment
  margin-top: 17px
  margin-right: 7%
  color: $ge-blauw
  float: right
  font-size: 1.5em

.indicatieVeld
  li
    display: block
    width: 100%
  i
    color: $ge-blauw
    margin-top: 10px
    text-align: right
    width: 7%
  input
    width: 92%
    margin-top: 5px
  div
    +span-columns(7)

#twofactor
  .modal-window
    min-width: 500px

#twofactorapps
  table
    width: 100%
    tr, th
      width: 20%
      text-align: center
      height: 30px
      font-size: 14px
      td:first-child
        text-align: left
      img
        height: 30px
        cursor: pointer
#twofactorpin
  .resetLink
    font-size: 1.5em
#twofactorsecret
  text-align: center
  p
    text-align: left

.twofactorsubmit
  margin-bottom: 0
  section
    width: 40%
    margin-left: 30%
    margin-right: 30%
    font-size: 1.2em

@media screen and (max-width: 1290px)
  .list-view, .list-heading
    > .list
      padding: 0px 10px 30px 10px


@import "tableList"


