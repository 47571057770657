form
  +span-columns(12)
  margin-bottom: 1em
  margin-top: 1em
  font-size: 0.8em
  font-weight: 300
  .left, .right

    +span-columns(6)
    min-height: 380px
    +pad(2em)
    label
      margin-bottom: 0.3em
      +span-columns(4)
  .right
    padding-right: 0px
    border-left: 1px solid $grijs
    +omega
  .left.apotheek
    min-height: auto
  &[name="bestelregel"]
    .left
      padding-left: 0px
    .right
      padding-right: 0px
  .opmerkingen
    +span-columns(12)

  .productBijzonderheden
    color: #E84616
    padding-left: 34%
    line-height: 1.8em
    margin-bottom: 1em

  .radio-buttongroup
    +span-columns(7)
    +shift(5)
    margin-bottom: 0.7em

  section
    +span-columns(12)
    line-height: $inputHeight
    margin-bottom: $sectionMargin
    position: relative
    +checkBoxFancy($iconColor, $ge-blauw, 2em)

    > .select-button-pair-button
      +span-columns(1)
    > .select-button-pair-select
      +span-columns(6)
    > .partial
      +span-columns(3.5)
      > label
        padding-left: .5em
    input, li.bestand
      +transition(0.15s)
      +span-columns(7)
      +omega
      +standardInputStyling

      &[type=radio]
        +span-columns(1)
      //margin-bottom: 0.7em
      &[type=radio] + label
        //margin: 0
        +span-columns(4)
    select
      background-color: $wit

    > label
      +span-columns(5)
      > span
        +transition(0.45s ease)
        float: right
        opacity: 0
        &.fa
          font-size: 1.5em
          line-height: 1.8em
        &[class*='fa-']
          +transition(0.45s ease)
          opacity: 1

    textarea, &#accorderen-opmerkingen, #bestel-opmerkingen
      +standardInputStyling
      font-size: 1.1em
      min-height: 5em
      min-width: 100%
      max-width: 100%
      padding-top: .6em
      +span-columns(12)
  h3
    font-weight: 300

  button
    //+span-columns(12)
    +omega()

input, select, .ui-select-container
  &.ng-valid, &.valid
    border-color: #dededd
    background-color: white
    box-shadow: none
    &.ng-valid-required
      border-color: #dededd

  &.ng-invalid, &.invalid
    &.ng-dirty
      border-color: #E84616 !important
      background-color: #FFEFEC !important

  &.ng-invalid-required, &.ng-valid-required
    border-color: $ge-blauw
    box-shadow: none

  &.inactive, &.ng-inactive, &[disabled]
    background-color: #EFEFEF !important
    border-color: #DEDEDD !important
    cursor: not-allowed

  &.small
    width: 7em

  + .eenheid
    margin-left: .5em

/* voor de multiselect */

.select2-container
  margin: 0
  position: relative
  display: inline-block
  /* inline-block for ie7 */
  zoom: 1
  *display: inline
  vertical-align: middle

.select2-container,
.select2-drop,
.select2-search,
.select2-search input
  -webkit-box-sizing: border-box
  /* webkit */
  -moz-box-sizing: border-box
  /* firefox */
  box-sizing: border-box
/* css3 */

.select2-container .select2-choice
  display: block
  //height: 26px
  padding: 0 0 0 8px
  overflow: hidden
  position: relative

  border: 1px solid #aaa
  white-space: nowrap
  line-height: 26px
  color: #444
  text-decoration: none

  border-radius: 4px

  background-clip: padding-box

  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  background-color: #fff

.select2-container.select2-drop-above .select2-choice
  border-bottom-color: #aaa
  border-radius: 0 0 4px 4px

.select2-container.select2-allowclear .select2-choice .select2-chosen
  margin-right: 42px

.select2-container .select2-choice > .select2-chosen
  margin-right: 26px
  display: block
  overflow: hidden

  white-space: nowrap

  text-overflow: ellipsis

.select2-container .select2-choice abbr
  display: none
  width: 12px
  height: 12px
  position: absolute
  right: 24px
  top: 8px

  font-size: 1px
  text-decoration: none

  border: 0
  cursor: pointer
  outline: 0

.select2-container.select2-allowclear .select2-choice abbr
  display: inline-block

.select2-container .select2-choice abbr:hover
  background-position: right -11px
  cursor: pointer

.select2-drop-mask
  border: 0
  margin: 0
  padding: 0
  position: fixed
  left: 0
  top: 0
  min-height: 100%
  min-width: 100%
  height: auto
  width: auto
  opacity: 0
  z-index: $super - 2
  /* styles required for IE to work */
  background-color: #fff
  filter: alpha(opacity=0)

.select2-drop
  width: 100%
  margin-top: -1px
  position: absolute
  z-index: $super - 1
  top: 100%

  background: #fff
  color: #000
  border: 1px solid #aaa
  border-top: 0

  border-radius: 0 0 4px 4px

  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .15)
  box-shadow: 0 4px 5px rgba(0, 0, 0, .15)

.select2-drop-auto-width
  border-top: 1px solid #aaa
  width: auto

.select2-drop-auto-width .select2-search
  padding-top: 4px

.select2-drop.select2-drop-above
  margin-top: 1px
  border-top: 1px solid #aaa
  border-bottom: 0

  border-radius: 4px 4px 0 0

  -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, .15)
  box-shadow: 0 -4px 5px rgba(0, 0, 0, .15)

.select2-drop-active
  border: 1px solid $ge-blauw
  border-top: none

.select2-drop.select2-drop-above.select2-drop-active
  border-top: 1px solid $ge-blauw

.select2-container .select2-choice .select2-arrow
  display: inline-block
  width: 18px
  height: 100%
  position: absolute
  right: 0
  top: 0

  border-left: 1px solid #aaa
  border-radius: 0 4px 4px 0

  background-clip: padding-box

  background: #ccc
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ccc), color-stop(0.6, #eee))
  background-image: -webkit-linear-gradient(center bottom, #ccc 0%, #eee 60%)
  background-image: -moz-linear-gradient(center bottom, #ccc 0%, #eee 60%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0)
  background-image: linear-gradient(top, #ccc 0%, #eee 60%)

.select2-container .select2-choice .select2-arrow b
  display: block
  width: 100%
  height: 100%

.select2-search
  display: inline-block
  width: 100%
  min-height: 26px
  margin: 0
  padding-left: 4px
  padding-right: 4px

  position: relative
  z-index: $super

  white-space: nowrap

.select2-search input
  width: 100%
  //height: auto !important
  min-height: 26px
  padding: 4px 20px 4px 5px
  margin: 0

  outline: 0
  font-family: sans-serif
  font-size: 1em

  border: 1px solid #aaa
  border-radius: 0

  -webkit-box-shadow: none
  box-shadow: none

.select2-drop.select2-drop-above .select2-search input
  margin-top: 4px

.select2-container-active .select2-choice,
.select2-container-active .select2-choices
  border: 1px solid #5897fb
  outline: none

.select2-dropdown-open .select2-choice
  border-bottom-color: transparent
  -webkit-box-shadow: 0 1px 0 #fff inset
    box-shadow: 0 1px 0 #fff inset

  border-bottom-left-radius: 0
  border-bottom-right-radius: 0

  background-color: #eee

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices
  border: 1px solid #5897fb
  border-top-color: transparent

.select2-dropdown-open .select2-choice .select2-arrow
  background: transparent
  border-left: none
  filter: none

.select2-dropdown-open .select2-choice .select2-arrow b
  background-position: -18px 1px

/* results */
.select2-results
  max-height: 200px
  padding: 0 0 0 4px
  margin: 4px 4px 4px 0
  position: relative
  overflow-x: hidden
  overflow-y: auto

.select2-results ul.select2-result-sub
  margin: 0
  padding-left: 0

.select2-results ul.select2-result-sub > li .select2-result-label
  padding-left: 20px

.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label
  padding-left: 40px

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label
  padding-left: 60px

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label
  padding-left: 80px

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label
  padding-left: 100px

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label
  padding-left: 110px

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label
  padding-left: 120px

.select2-results li
  list-style: none
  display: list-item
  background-image: none

.select2-results li.select2-result-with-children > .select2-result-label
  font-weight: bold

.select2-results .select2-result-label
  padding: 3px 7px 4px
  margin: 0
  cursor: pointer

  min-height: 1em

  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.select2-results .select2-highlighted
  background: #3875d7
  color: #fff

.select2-results li em
  background: #feffde
  font-style: normal

.select2-results .select2-highlighted em
  background: transparent

.select2-results .select2-highlighted ul
  background: #fff
  color: #000

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit
  background: #f4f4f4
  display: list-item

.select2-results .select2-disabled.select2-highlighted
  color: #666
  background: #f4f4f4
  display: list-item
  cursor: default

.select2-results .select2-disabled
  background: #f4f4f4
  display: list-item
  cursor: default

.select2-results .select2-selected
  display: none

.select2-more-results
  background: #f4f4f4
  display: list-item

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice
  background-color: #f4f4f4
  background-image: none
  border: 1px solid #ddd
  cursor: default

.select2-container.select2-container-disabled .select2-choice .select2-arrow
  background-color: #f4f4f4
  background-image: none
  border-left: 0

.select2-container.select2-container-disabled .select2-choice abbr
  display: none

/* multiselect */

.select2-container-multi .select2-choices
  //height: auto !important
  height: 100%
  margin: 0
  padding: 0
  position: relative

  cursor: text
  overflow: hidden

.select2-locked
  padding: 3px 5px 3px 5px !important

.select2-container-multi.select2-container-active .select2-choices
  border-radius: $borderRadiusStandard
  border: 1px solid $ge-blauw
  outline: none

  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3)
  box-shadow: 0 0 5px rgba(0, 0, 0, .3)

.select2-container-multi .select2-choices li
  float: left
  list-style: none

.select2-container-multi .select2-choices .select2-search-field
  margin: 0
  padding: 0
  white-space: nowrap

.select2-container-multi .select2-choices .select2-search-field input
  padding: 5px
  margin: 1px 0

  font-family: sans-serif
  font-size: 100%
  color: #666
  outline: 0
  border: 0
  -webkit-box-shadow: none
    box-shadow: none
  background: transparent !important

.select2-default
  color: #999 !important

.select2-container-multi .select2-choices .select2-search-choice
  padding: 3px 5px 3px 18px
  margin: 3px 0 3px 5px
  position: relative

  line-height: 13px
  color: #333
  cursor: default
  border: 1px solid #aaaaaa

  border-radius: 3px

  -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)

  background-clip: padding-box

  -webkit-touch-callout: none
    -webkit-user-select: none
      -moz-user-select: none
        -ms-user-select: none
          user-select: none

  background-color: #e4e4e4

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen
  cursor: default

.select2-container-multi .select2-choices .select2-search-choice-focus
  background: #d4d4d4

.select2-search-choice-close
  display: block
  width: 12px
  height: 13px
  position: absolute
  right: 3px
  top: 4px

  font-size: 1px
  outline: none

.select2-container-multi .select2-search-choice-close
  left: 3px

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover
  background-position: right -11px

.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close
  background-position: right -11px

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices
  background-color: #f4f4f4
  background-image: none
  border: 1px solid #ddd
  cursor: default

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice
  padding: 3px 5px 3px 5px
  border: 1px solid #ddd
  background-image: none
  background-color: #f4f4f4

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close
  display: none
  background: none

/* end multiselect */

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match
  text-decoration: underline

.select2-offscreen, .select2-offscreen:focus
  clip: rect(0 0 0 0) !important
  width: 1px !important
  height: 1px !important
  border: 0 !important
  margin: 0 !important
  padding: 0 !important
  overflow: hidden !important
  position: absolute !important
  outline: 0 !important
  left: 0px !important
  top: 0px !important

.select2-display-none
  display: none

.select2-measure-scrollbar
  position: absolute
  top: -10000px
  left: -10000px
  width: 100px
  height: 100px
  overflow: scroll

.ui-select-match-close
  text-decoration: none
  &:before
    line-height: 10px
    font-size: 16px
    color: grey
    border: 0
    cursor: pointer

input[type=file]
  visibility: hidden
  position: absolute
  padding: 0
  &:disabled
    &:before
      background-color: $grijs

.preference
  background-color: #005cb9 !important
  color: white !important

.organisaties li.ui-select-match-item
  padding: 0 !important
  margin: 0 !important
  position: initial !important
  line-height: 13px !important
  color: initial !important
  cursor: initial !important
  border: 0 !important
  border-radius: 0 !important
  background-clip: padding-box !important
  background-color: initial !important

.organisatie-item
  padding: 3px 5px 3px 18px
  margin: 3px 0 3px 5px
  position: relative
  line-height: 13px
  color: #333
  cursor: default
  border: 1px solid #aaaaaa
  border-radius: 3px
  background-clip: padding-box
  background-color: #e4e4e4

.organisaties-info
  margin-left: 43%
  margin-top: 0.2em
  line-height: 1.5em

form section .ui-select-container
  margin-right: 0
  
.dropzone
  border: 1px dashed grey
  width: 100%
  text-align: center
  line-height: 5.6em
  *
    cursor: pointer
  &:hover, &.dz-drag-hover
    background-color: $ge-blauw
    color: white
  *:active
    background-color: darken($ge-blauw, 30%)
  
.dropzone-container
  +span-columns(7)
  width: 100%
  .dz-preview
    background-color: #EFEFEF
    border: 1px solid #DEDEDE
    border-radius: 3px
    padding-left: 10px
    position: relative
    margin-top: 5px
    .dz-filename
      white-space: nowrap
      width: 90%
      text-overflow: ellipsis
      overflow: hidden
    .dz-remove
      position: absolute
      top: 3px
      right: 3px
      height: 30px
      &, > img
        cursor: pointer
      
.bestanden
  float: left
  +span-columns(7)
  .bestand
    border: 1px solid #DEDEDE
    border-radius: 3px
    padding-left: 10px
    position: relative
    list-style-type: none
    margin-bottom: 5px
    width: 100%
    .bestandsnaam
      white-space: nowrap
      width: 90%
      text-overflow: ellipsis
      overflow: hidden
    .remove
      position: absolute
      top: 3px
      right: 3px