.container

  +transition(0.25s ease)
  +shadow-bottom

  font-size: .88em
  left: 0%
  height: 0px
  overflow: hidden
  color: $donkergrijs
  display: block
  width: 30em
  background-color: $filtersBgColor
  border-bottom-left-radius: $borderRadiusStandard
  border-bottom-right-radius: $borderRadiusStandard
