#artsenverklaring2020
  input
    font-size: 1em
    font-style: italic
    background-color: transparent !important
    border-radius: $borderRadiusStandard
    line-height: 1.8em
    cursor: default
    &.ng-invalid-required 
      @extend input, .ng-invalid, .ng-dirty
    &.inactive, &.ng-inactive, &[disabled]
      border: none
      border-radius: 0
      border-bottom: 1px solid $grijs
  .modal-window
    form
      .rijksoverheid
        +span-columns(11)

        .logo
          background: url("../images/rijksoverheid.png") no-repeat
          background-size: contain
          height: 15em
          +span-columns(1.5)
          +shift(5)

        .text
          +span-columns(5)

          color: $zwart
          margin-top: 10em
          h1
            font-size: 1.8em
          h2
            font-size: 1em
            font-style: italic

      .summary
        line-height: 1.2
        h1
          +shift(0)
          +span-columns(12)
          font-size: 1.2em
        p
          font-size: 1em
        ul
          +pad((1em, 0))
          padding-inline-start: 1em
        ol
          +pad((1em, 0))
          padding-inline-start: 1em
          list-style-type: lower-alpha

      .body
        h1
          margin-bottom: 1em
        .row
          line-height: 1.1
          margin-bottom: 1em
          .ui-select-container
            line-height: 2.8em
            position: relative
      section
        font-size: 1.1em
        color: $zwart
        h1
          +shift(4)
          +span-columns(8)
          font-size: 1.1em
        +pad((3em, 3em, 0, 3em))
        .row
          +span-columns(12)
          label
            text-align: left
            line-height: 1.8em
            +span-columns(4)
          input
            +span-columns(6)
            outline: none
            &.breed-veld
              +span-columns(8)

          &.double
            +shift(3.65)
            +span-columns(8)
            .first, .second
              +span-columns(6)

            label
              +span-columns(6)

            input
              +span-columns(12)

          &.handtekening-datum
            .handtekening
              +span-columns(4)
              label
                +span-columns(0)
                +omega()
              input
                +span-columns(12)
            .plaats
              +span-columns(4)
              label
                +span-columns(0)
                +omega()
              input
                +span-columns(12)
            .datum
              +span-columns(4)
              +omega
              label
                +span-columns(12)
                +omega()
              input
                +span-columns(12)
                +omega
          &.naam-geslacht
            .name
              label
                +span-columns(4)
              input
                +span-columns(6)
            .geslacht
              label
                +span-columns(1)
              input
                +span-columns(1)
          &.text
            +shift(3.7)
            +span-columns(8)
            p
              font-size: 1em
