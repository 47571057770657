.password-policy
  z-index: $super
  list-style: none
  display: block
  width: 28em
  max-height: 0em
  position: fixed
  border-radius: $borderRadiusStandard
  +pad(.5em)
  background-color: white
  right: 1em
  top: 18em
  opacity: 0
  +shadow-bottom
  span
    +span-columns(1.4)
    float: left
    line-height: 1
  .fa
    margin-top: .2em
    font-size: 2em
  .fa-check-circle
    color: $success
    +transition(.25s ease)
  .fa-minus-circle
    color: $error
    +transition(.25s ease)
  &.password-policy-show
    max-height: 20em
    opacity: 1
    +transition(.25s ease)
  &.password-policy-hide
    opacity: 0
    +transition(.25s ease)
