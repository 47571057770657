$background: #F2EFEB
//#D3D3D3
$ge-blauw: rgb(0, 92, 185)
$secondary-blauw: #00aae7

//$ge-blauw: $secondary-blauw
//#f1ea36
// #041d48
$lichtblauw: $secondary-blauw

$lichtgroen: #40c312
$wit: #ffffff

$oranje: #ff9c00
$rood: #e21313
$donkergrijs: #828282
$grijs: #dcdcdc
$lichtgrijs: $background
//#f3f3f3
$zwart: #131313
$paars: #5f0ECA

//GE colors
$deselectedText: #797979

//Header, titlebar
$titleBarBackground: $wit
//#041d48
//$wit
$titleBarButtonColor: $ge-blauw

//input and forms
$selectHover: $ge-blauw

// FILTERS
$filterBarBackground: $ge-blauw
$filterBarTextColor: $wit
$filtersBgColor: #ffffff
$filterSelectHover: $selectHover

//Toolbar
$toolBarBackground: #515151

//tabbar
$tabBarBackground: $wit

$listItemBackground: $wit

//#f9f9f9

$bodyTextColor: $zwart
$headerTextColor: $ge-blauw

$success: #51a351
$error: #bd362f
$info: $ge-blauw
$warning: #f89406

$transparant: rgba(0, 0, 0, 0.0)

//buttons
$secondaryButtonColor: $ge-blauw

$toolBarButtonColor: $wit
$toolBarButtonBGcolor: $wit

$secondaryButtonBorderColor: $ge-blauw
$secondaryButtonBGColor: $transparant

$primaryButtonBGColor: $ge-blauw
$primaryButtonBorderColor: $ge-blauw

//forms
$standardFormBorderColor: $grijs
$standardFormBorderColorActive: $ge-blauw

//TOASTERS
$textColor: #ffffff
$textColorHover: #cccccc
$closeButton: #ffffff
$closeButtonHover: #000000

$toast: #030303
$toastSuccess: $success
$toastError: $error
$toastInfo: $info
$toastWarning: $warning

$toastContainerColor: #ffffff
$toastContainerShadowColor: #999999
$toastContainerShadowColorHover: #000000