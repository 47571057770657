.modal

  overflow: scroll
  color: $donkergrijs
  +transition(opacity 0.35s ease)
  +position(fixed, 0px 0px 0px 0px)
  background: rgba(0, 0, 0, 0.55)
  opacity: 1

  text-align: left
  visibility: visible
  z-index: $high

  > .modal-window
    position: relative
    +pad(2em)
    +outer-container
    +transition(opacity .35s ease)
    +box-shadow
    background: $wit
    margin: 100px auto

    p
      font-size: 0.95em

    &.large

      min-height: 400px
    &.medium
      +span-columns(8)
      +shift(2)

      +media($normal-desktop)
        +span-columns(6)
        +shift(3)

    &.small
      +span-columns(5)
      +shift(3.5)

    &.artsenverklaring
      +span-columns(8)
      +shift(2)
      height: 80%
      max-width: 70em
    &.notities
      +span-columns(8)
      +shift(2)
      max-width: 70em
    &.ziekenhuiskeuze
      +span-columns(5)
      +shift(3.5)
      > section
        > form
          min-height: 20em
    &.datumselectie
      width: 40em
      max-width: 70em
      .date-picker
        margin: 1em
        .content
          margin: auto
          width: 100%
          .hasDatepicker
            float: left
            margin: auto
            line-height: 1.4em
            width: 50%

  &#login-modal
    color: $wit
    background: none
    > .modal-window
      margin-top: 200px
      overflow: hidden
      background: $ge-blauw
      +no-shadow
      h1
        color: white
  
  > .modal-window, &.modalview
    .title
      margin-top: -.5em
      font-size: 1.5em
    .modal-counter
      font-size: 4em
      right: .9em
      background: white
      display: block
      top: .5em
      position: absolute

  &.modalview
    background: transparent
    z-index: $low
    .list-view
      top: 170px
      overflow: visible
    .list-heading
      top: 50px
      overflow: visible
    .modal-window
      overflow-y: visible
      background: $wit
      +box-shadow

#configuratie > .modalview
  color: $bodyTextColor
  top: 101px
  h1, h3
    margin-bottom: 10px
  .list-heading .headings .heading
    height: 32px

.footer
  z-index: 100
footer
  +span-columns(12)
  bottom: 0
  margin-top: 1em
  text-align: right
  > button, a.button
    max-height: 3em

  > .title
    margin-top: -.5em
    font-size: 1.2em
    color: $donkergrijs

#account-modal
  top: $titleBarHeight
  .modal-window section h1
    font-size: 1.17em
  #twofactorsecretWrapper
    font-size: .8em
  #twofactorsecret
    font-size: 1.25em

#artsenverklaring,
#artsenverklaring2020,
#artsenverklaring2022
  > .modal-window

    > section

      > form
        position: absolute
        top: 7em
        bottom: 6em
        overflow-y: scroll
        left: 0em
        right: 0em
      footer
        +span-columns(11)
        +position(absolute)
        bottom: 1em
        margin-top: 1em
        text-align: right
        > button, a.button
          max-height: 3em

        > .title
          margin-top: -.5em
          font-size: 1.2em
          color: $donkergrijs


