#toolbar
  @include user-select(none)
  +fill-parent
  // +row
  +position(fixed)
  bottom: 0
  padding: $paddingToolbar
  background-color: $toolBarBackground
  font-family: "Open Sans", arial, helvetica, clean, sans-serif
  font-weight: 300
  font-size: 1.3em
  min-height: $toolbarHeight
  line-height: $toolbarHeight

  aside
    +span-columns(2)
    text-align: left
    max-height: $toolbarHeight
    img
      max-height: $toolbarHeight

  #actions
    /*mobile*/
    +media($mobile)
      +span-columns(6)
      +pad

    text-align: right
    height: inherit
    +span-columns(10)

    .footer-label
      color: white
      font-size: 0.95rem
