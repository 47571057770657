.accordeon
  @include user-select(none)
  +span-columns(12)
  header
    z-index: $bottom
    +transition(0.25s ease)
    &.invalid
      +transition(0.25s ease)
      background: darkgrey
    +row
    +position(relative)
    +span-columns(12)
    padding-left: 1em
    padding-right: 1em
    background: $ge-blauw
    color: $wit
    border-radius: $borderRadiusStandard
    &.warning
      background-color: $warning
    &.error
      background-color: $error
    > h3
      color: $wit
      +span-columns(8)
      font-weight: 400
      font-size: 0.8em
      line-height: 3
    > span
      +span-columns(4)
      line-height: 2
      text-align: right

  color: $donkergrijs
  border: $standardBorder solid $grijs
  border-radius: $borderRadiusStandard

  .fold
    button
      margin-bottom: 1em
    padding-left: 1em
    padding-right: 1em
    +transition(0.25s ease)
    +row
    +span-columns(12)
    max-height: 0em
    overflow: hidden
    display: block
    visibility: hidden
    margin-bottom: 0em
    &.visible
      margin-top: .8em
      visibility: visible
      overflow: visible
      max-height: 100em
      +transition(0.25s ease)

    .primary-button
      float: right
  &.bestelling
    header
      h3
        text-transform: none
        font-size: 1.05em
        +span-columns(7)
      div
        +transition(0.25s ease)
        +span-columns(.75)
        font-size: 1.4em
        line-height: 2
  &.sapcode
    header
      h3
        text-transform: none
        font-size: 1.05em
        +span-columns(7)
      div
        +transition(0.25s ease)
        +span-columns(.75)
        font-size: 1.4em
        line-height: 2.3
      span
        line-height: 3