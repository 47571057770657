.filtercontainer
  @extend .container

  +position(absolute)
  z-index: $medium
  visibility: hidden

  &.s
    width: 10em
  &.m
    width: 20em
  &.l
    width: 30em
  &.xl
    width: 40em
  &.xxl
    width: 50em
  &.date-picker
    width: 44em
  &.status
    width: 20em
  .content
    > header
      +position(relative)
      +span-columns(11.5)
      +shift(.25)
      margin-bottom: 10px
      height: 50px
      border-bottom: $standardBorder solid $grijs
      text-align: center
      line-height: 50px

    > .ui-select-filter
      +position(static)
      top: 0px
      bottom: 36px
      +pad(0)
      .selectize-input
        border: none
        border-bottom: 1px solid $filterBarBackground
        border-radius: 0
        height: 50px
        position: absolute
        top: 0
        left: 0
        right: 0
        > input
          font-size: 1.1em
          height: 50px
          padding-left: 1em
      .selectize-dropdown
        background: none
        width: 100%
        top: 50px
        bottom: 36px
        overflow-y: scroll
        max-height: 50em

    > section
      +position(absolute)
      top: 50px
      bottom: 36px
      overflow: hidden
      +pad(2em)
      +span-columns(12)
      > section
        margin-top: 1em
        +checkBoxFancy($iconColor, $ge-blauw, 2em)
        line-height: 2em
        input
          +span-columns(12)
          +standardInputStyling
      > .hasDatepicker
        line-height: 1.3
        +span-columns(6)
    > footer
      +span-columns(12)
      +position(absolute)
      bottom: 0
      height: 50px
      text-align: center
      font-size: 2em
      button
        position: relative

//ui-selectstyling voor in filter container
.active-filters
  +position(absolute)
  top: 120px
  width: inherit
  margin-top: 2.5em
  +media($compensateFilterBarWidth)
    top: $titleBarHeight*3.1
  &.has-filters
    border-bottom: 2px solid $grijs
    padding-bottom: .2em
  +transition(0.25s ease)
  > li
    background-color: $wit
    margin-left: 1em
    margin-bottom: .5em
    list-style: none
    +transition(ease .25s)
    color: $toolBarBackground
    +pad(.5em)
    display: inline-block
    +box-shadow
    font-size: .85em
    &:hover
      +transition(ease .25s)
      +shadow-bottom


