.list-view, .list-heading
  &.organisatiebeheer
    .locatie
      +span-columns(9)
      +omega
      > .column
        border: none
      &.shift
        +shift(2)

    ul
      li
        height: auto
  &.mappingbeheer
    .farmacon

      +span-columns(9)
      +omega
      > .column
        border: none
      &.shift
        +shift(3)

    ul
      li
        height: auto