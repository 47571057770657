.tooltip

  line-height: 1.5em
  width: auto
  position: absolute
  bottom: 4em
  z-index: $super
  list-style: none
  display: block
  max-height: 0em
  border-radius: $borderRadiusStandard
  word-break: break-all
  +pad(.5em)
  background-color: white
  opacity: 0
  +shadow-bottom
  span
    +span-columns(1.4)
    float: left
    line-height: 1
  &.tooltip-show
    max-height: 20em
    opacity: 1
    +transition(.25s ease)
  &.tooltip-hide
    opacity: 0
    +transition(.25s ease)
