.errors
  +position(relative)
  right: -0em
  top: -0em

  background-color: white
  +span-columns(7)
  +shift(5)
  text-align: left
  color: $error
  padding-left: 0.5em
  padding-right: 0.5em
  margin-top: 0.2em
  +box-shadow
  p
    //border: 1px solid $warning
    border-radius: $borderRadiusStandard
    line-height: 1em

.left, .right
  .errors
    +span-columns(7)
    +shift(4)

.partial
  .errors
    +span-columns(12)
    +shift(0)