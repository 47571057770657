@import font-awesome-4.3.0/scss/font-awesome
@import ../app/assets/bower_components/angularjs-toaster/toaster
@import ../app/assets/bower_components/sweetalert/dev/sweetalert
/** Globale dependencies*/
@import ../app/assets/bower_components/bourbon/app/assets/stylesheets/bourbon
@import settings/neat
@import ../app/assets/bower_components/neat/app/assets/stylesheets/neat

/** Settings voor onze app (kleuren en layout)*/
@import settings/colors
@import settings/variables

/** helpers*/
@import helpers/fonts
@import helpers/loading
@import helpers/tags
@import helpers/mixins
@import helpers/bower-copy/ui-select
@import helpers/bower-copy/ui-date

/** components*/
@import components/base
@import components/container
@import components/tabbar
@import components/filterbar
@import components/header
@import components/toolbar
@import components/list/list
@import components/modals
@import components/forms
@import components/accordeon
@import components/filters
@import components/detailWeergave
@import components/artsenverklaring
@import components/artsenverklaring2020
@import components/artsenverklaring2022
@import components/notities
@import components/tooltip
@import components/passwordpolicy
@import components/overlay
@import components/ondertekenen
@import components/modalErrorLog
@import components/validationerrors
@import components/printview
@import components/excelpopup
@import components/mappingbeheer
@import components/datumPreset
@import components/werklijstoverzicht

*
  -webkit-font-smoothing: antialiased
  cursor: default

html
  overflow-x: hidden

body
  background-color: $background
  color: $bodyTextColor
  font-size: 16px

.hide
  display: none !important

button.primary-button.inactive
  background-color: #efefef
  border-color: #eee
  span
    color: #eee

.version-info
  +span-columns(12)
  display: block
  position: absolute
  bottom: 2em
  color: $donkergrijs
  z-index: $high
  text-align: center
  font-size: .8em
