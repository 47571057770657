.dropdown-overlay
  position: fixed
  background-color: grey

  background-size: contain
  opacity: 0
  z-index: -1
  transition: 0.3s ease opacity
  &.show
    opacity: .2
    transition: 0.3s ease opacity
    display: block
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: $medium