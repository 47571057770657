.filterbar
  +fill-parent
  @include user-select(none)

  +span-columns(12)
  position: relative
  top: $filterbarTop+$standardBorder+$standardBorder
  &#dashboard
    top: $titleBarHeight
  background-color: $filterBarBackground
  height: auto
  list-style: none
  +shadow-bottom
  +media($mobile)
    +span-columns(6)
  > li
    //+shadow-bottom
    position: relative
    +media($mobile)
      +span-columns(6)
    &.date-picker-button
      color: $ge-blauw
      background-color: white
      line-height: 48px
      &:hover > .preset-panel
        max-height: 6em
        padding: 0.5em
        overflow: hidden
    &.open > .filtercontainer
      visibility: visible
      +transition(0.25s ease)
      z-index: $upper
      height: $filtersHeight * 6
      &.date-picker
        height: $filtersHeight * 7
      &.status
        height: $filtersHeight * 9

    max-height: 50px
    +transition(0.20s)
    box-sizing: border-box
    +pad(0px 5px 0px default)
    line-height: $filterbarHeight
    color: $filterBarTextColor
    text-transform: uppercase
    font-size: 0.82em
    list-style-type: none
    font-weight: 600
    display: inline-table

    cursor: pointer
    > input
      border: none
      outline: none
      line-height: 1.7em
      font-size: 1em
    > .fa-angle-down
      font-size: 1.5rem
      visibility: hidden
      vertical-align: middle
      padding-bottom: 0.16em
      margin-left: 0.4em
    &:hover
      > .fa-angle-down
        visibility: visible
    &.activated
      border-bottom: $wit $filterbarBorder solid
    &#date-selector
      /*mobile*/
      +media($mobile)
        +span-columns(6)
      padding-right: 2em
      //border-right: 1px solid $background
      white-space: nowrap

    &#offsetToggle
      margin-right: -1em

    &.toggle
      > .switch
        +position(absolute)
        top: -99em
        $toggleHeight: 1.5em
        $toggleWidth: $toggleHeight * 2.5
        + label
          vertical-align: middle
          cursor: pointer
          +transition(0.25s)
          display: inline-block
          border-radius: 0.00em
          border: 2px solid $wit
          width: $toggleWidth
        &:checked + label
          //border-color:
          background-color: $success
        + label:before
          +transition(0.25s)
          margin: 0em
          content: ''
          border-radius: 0.00em
          display: block
          height: $toggleHeight
          width: $toggleWidth / 2
          background-color: $lichtgrijs
        &:checked + label:before
          margin-left: $toggleWidth / 2.4

      > .icons
        pointer-events: none
        margin-top: -2.6em
        color: #F2EFEB
        font-size: 1.5em
        > .positive
          margin-left: 1.55em
        > .negative
          margin-left: 0.45em
