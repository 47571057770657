@import "print-preview/artsenverklaring"
.page
  background-color: white
  +box-shadow()
  top: 80px
  left: 2em
  right: 2em
  bottom: 4em
  position: absolute
  overflow-y: scroll
  +pad(1em)

.print-header
  padding: 0.3em
  position: relative
  +span-columns(12)

.ondertekening
  margin-top: 1em
  margin-bottom: 2em
  +span-columns(12)
  position: relative
  .naam, .handtekening
    height: 5em
    +span-columns(5.5)
    +shift(.25)
    position: relative
    border-bottom: 1px solid black

.printregel
  padding: 0.3em

  +span-columns(12)
  border: 1px solid $donkergrijs
  margin-bottom: 1em
  .printregel-header
    +span-columns(12)
    position: relative
    border-bottom: 1px solid $donkergrijs

    .printregel-info, .printregel-info-2
      > span
        display: block
        float: left
        width: 16.6%
        word-break: break-all

    table, tbody
      +span-columns(12)
      border-spacing: 0
      border-collapse: collapse

      tr
        width: 100%
        float: left
        display: block
        th
          text-align: left
        td, th
          width: 16.6%
          display: block
          float: left
          word-break: break-all

          &:last-child
            float: right

          &[colspan="2"]
            width: 33.2%

          &[colspan="3"]
            width: 49.8%

          &[colspan="4"]
            width: 66.4%

  .printregel-organisatie
    border-top: 2px solid black
  .printregel-content
    +span-columns(12)
    padding-top: .5em

    position: relative
    table, tbody
      +span-columns(12)
      border-spacing: 0
      border-collapse: collapse
      tr
        width: 100%
        float: left
        display: block
        th
          text-align: left
        td, th
          width: 16.6%
          display: block
          float: left
          word-break: break-all

          &:last-child
            float: right

          &[colspan="2"]
            width: 33.2%

          &[colspan="3"]
            width: 49.8%

          &[colspan="4"]
            width: 66.4%

          &[colspan="5"]
            float: left
            width: 100%

    .geleverd-product
      border-top: 1px solid $donkergrijs
      padding-top: .5em

    .bijzonderheden
      +span-columns(12)
      border-top: 1px solid $donkergrijs
      padding-top: .5em

    .notities-seperator
      +span-columns(12)
      border-top: 1px solid $donkergrijs
      padding-top: .5em


.notities
  position: relative
  list-style: none
  padding-top: .5em
  left: 0em

  .notitie
    +span-columns(12)
    max-height: 30em
    border-top: 1px solid lightgrey

    .sender-information
      +span-columns(2)
      .user
        line-height: 1.5em

      .date-time
        min-height: 2em

        .om
          font-style: italic
          font-size: .9em

    .bericht-container
      +span-columns(9)
      min-height: 4.9em
      border-left: 1px solid lightgrey

      .bericht
        padding-left: 1em
        padding-top: 0.5em
        min-height: 2.8em

      .gelezen
        padding-top: 0.5em
        padding-left: 1em
        padding-bottom: 0.5em