header
  @include user-select(none)
  +fill-parent()
  +position(absolute)
  color: $headerTextColor
  white-space: nowrap
  .ingelogd-als
    float: left
    line-height: 0em
    padding: .6em 0
    text-align: left

    span
      line-height: 1.6em

.title-bar
  font-size: 0.85em
  line-height: $titleBarHeight
  background: $titleBarBackground
  min-height: $titleBarHeight
  max-height: $titleBarHeight
  //+shadow-bottom
  .logo
    +span-columns(2)
    font-size: 1.5em
    padding-left: 1em
    cursor: pointer
    > h3
      cursor: pointer
      display: block
      width: 50px
      float: left

    > #logo
      cursor: pointer
      display: block
      float: left
      background: url('../images/monogram.png') no-repeat
      background-size: contain
      height: $titleBarHeight
      width: $titleBarHeight
  .organisation-dropdown
    +span-columns(2)
  .help
    +span-columns(0.4)
    .fa-question-circle
      vertical-align: middle
      &:before
        font-size: 2em
        cursor: pointer
    .container
      height: auto
      margin-left: -25em
  .user-controls
    +span-columns(7.4)
    height: $titleBarHeight
    text-align: right
    .dropdown
      text-transform: uppercase
      display: inline-block
      position: relative
      max-height: $titleBarHeight
      padding-left: 0.1em
      text-align: left
      min-width: 15em
      z-index: $medium
      .dropdown-content
        visibility: hidden
        height: 0px
        list-style-type: none
        text-align: left
        position: absolute
        +transition(0.25s ease)
        left: 0
        right: 0
        background-color: $wit
        overflow: hidden
        +box-shadow
        li
          button
            width: 100%
            text-align: left

      &:hover > .dropdown-content
        visibility: visible

        +transition(0.25s ease)
        height: $titleBarHeight * 2

  .container
    z-index: $upper
    overflow: auto
    height: 25em
    position: relative
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5)

    &.apotheekDropdown
      height: 14em
      z-index: $upper

    div, a
      display: block
      padding-left: 1em
      padding-right: .5em

    div
      font-size: 1.2em
      background-color: #EFEFEF
      border-top: 1px solid #dddddd
      border-bottom: 1px solid #dddddd

    a
      background-color: #fff
      display: block
      cursor: pointer
      font-size: 1.4em

      &:hover
        background-color: $ge-blauw
        color: #ffffff
