#excelpopup
  background-image: none

  body
    background-color: white

    .primary-button
      @extend button
      cursor: pointer
      text-decoration: none

  .modal-window > div
    margin: 1em 0 2em

  ol
    margin: 1em 0 2em
    padding-left: 1.8em

    li
      line-height: 2em
      background-color: #EEEEEE
      margin-bottom: 2px
      border: 1px solid #EAEAEA
      padding: 0 0.5em
      border-radius: .2em

  input[type=file]
    visibility: visible
    width: 100%
    border: none
    float: none
    position: relative
    margin-bottom: 2em