#notities
  > .modal-window

    > section

      > form
        position: relative
        +span-columns(12)
        text-align: right
        border-bottom: 1px solid $lichtgrijs
        padding-bottom: 1em
      footer
        +span-columns(12)
        +position(absolute)
        bottom: 1em
        margin-top: 1em
        text-align: right
        > button, a.button
          max-height: 3em

        > .title
          margin-top: -.5em
          font-size: 1.2em
          color: $donkergrijs

.notitie-container
  position: relative
  height: 20em
  +span-columns(12)

  .bottom-shadow
    -webkit-box-shadow: inset 0px -27px 5px -27px rgba(0, 0, 0, 0.75)
    -moz-box-shadow: inset 0px -27px 5px -27px rgba(0, 0, 0, 0.75)
    box-shadow: inset 0px -27px 5px -27px rgba(0, 0, 0, 0.75)
    position: absolute
    bottom: 0em
    left: 0em
    right: 0em
    height: .2em
    opacity: 0
    z-index: $high
    +transition(.5s ease)
    &.scrolling
      +transition(.5s ease)
      opacity: 1

  .top-shadow
    -webkit-box-shadow: inset 0px 27px 5px -27px rgba(0, 0, 0, 0.75)
    -moz-box-shadow: inset 0px 27px 5px -27px rgba(0, 0, 0, 0.75)
    box-shadow: inset 0px 27px 5px -27px rgba(0, 0, 0, 0.75)
    position: absolute
    top: 0em
    left: 0em
    right: 0em
    height: .2em
    opacity: 0
    z-index: $high
    +transition(.5s ease)
    &.scrolling
      +transition(.5s ease)
      opacity: 1

  .notitie-container-scrollable
    position: absolute
    top: 0em
    bottom: 0em
    overflow-y: scroll
    padding: .2em
    left: 0em
    right: 0em
    background: rgba(236, 236, 236, 1)
    border-radius: $borderRadiusStandard

.notitie-list
  position: relative
  list-style: none
  margin: 1em

  &:after
    content: ' '
    display: inline-block
    height: .5em
    width: 100%

  .notitie
    background: white
    +span-columns(12)
    +box-shadow
    max-height: 20em
    +pad(.7em)
    font-size: .9em
    margin-bottom: 1em
    $notitieColumnHeight: 5em

    &:last-child
      margin-bottom: 0

    .new
      background-color: #f20

    .sender-information
      +span-columns(3)
      .user
        +span-columns(12)
        +ellipsis
        line-height: 1.5em

        .functie
          font-style: italic

      .date-time
        min-height: 2em
        +span-columns(12)
        +ellipsis

        .om
          font-style: italic
          font-size: .8em

    .bericht-gelezen-container
      min-height: 4.9em
      +span-columns(9)
      border-left: 1px solid lightgrey

      .bericht
        padding-left: 1em
        min-height: 2.8em

      .gelezen
        padding-top: 0.5em
        padding-left: 1em
        padding-bottom: 0.5em
