=listItemStandardStyle
  //base-style for standard list-item
  +transition(0.20s)
  +span-columns(12)
  //border-bottom: 2px $grijs solid
  @include user-select(none)
  height: $bestelregelHoogte
  line-height: $bestelregelHoogte
  margin-bottom: 0px
  //border: 1px $lichtgrijs solid
  border-radius: 0
  // $borderRadiusStandard
  font-size: .845em
  background-color: $listItemBackground
  +box-shadow
  list-style-type: none
  &.multi-row
    height: auto
  .column
    min-height: $bestelregelHoogte
  &.darker
    background-color: darken($listItemBackground, 5%)


=listItemWarningClasses
  &.warning-high
    background-color: lighten($rood, 49%)
    -webkit-box-shadow: inset -30px -0px 0px -22px $rood, inset 18px -0px 0px -10px $rood
    box-shadow: inset -30px -0px 0px -22px $rood, inset 18px -0px 0px -10px $rood
    -moz-box-shadow: inset -30px -0px 0px -22px $rood, inset 18px -0px 0px -10px $rood
  &.warning-medium
    background-color: lighten($oranje, 45%)
    -webkit-box-shadow: inset -30px -0px 0px -22px $oranje, inset 18px -0px 0px -10px $oranje
    box-shadow: inset -30px -0px 0px -22px $oranje, inset 18px -0px 0px -10px $oranje
    -moz-box-shadow: inset -30px -0px 0px -22px $oranje, inset 18px -0px 0px -10px $oranje
  &.warning-low
    background-color: lighten($ge-blauw, 60%)
    -webkit-box-shadow: inset -30px -0px 0px -22px $ge-blauw, inset 18px -0px 0px -10px $ge-blauw
    box-shadow: inset -30px -0px 0px -22px $ge-blauw, inset 18px -0px 0px -10px $ge-blauw
    -moz-box-shadow: inset -30px -0px 0px -22px $ge-blauw, inset 18px -0px 0px -10px $ge-blauw
  &.warning-direct-verzonden
    background-color: lighten($paars, 54%)
    -webkit-box-shadow: inset -30px -0px 0px -22px $paars, inset 18px -0px 0px -10px $paars
    box-shadow: inset -30px -0px 0px -22px $paars, inset 18px -0px 0px -10px $paars
    -moz-box-shadow: inset -30px -0px 0px -22px $paars, inset 18px -0px 0px -10px $paars

=listItemColumnClasses
  //Algemene columns
  &.first
    padding-left: 1em
  &.last
    border-right: none
    +omega
  &.s
    +span-columns($smallColumn)
  &.m
    +span-columns($mediumColumn)
  &.l
    +span-columns($largeColumn)
  &.xl
    +span-columns($extraLargeColumn)
  &.xxl
    +span-columns($extraExtraLargeColumn)
  &.xl4
    +span-columns($xl4Column)
  &.xl10
    +span-columns($xl10Column)
  &.xl11
    +span-columns($xl11Column)

  //Dashboard columns
  &.accorderen
    +span-columns(.6)
  &.patient-nr
    +span-columns($columnPatientNr)
  &.gewicht
    +span-columns($columnGewicht)
  &.behandeling
    +span-columns($columnBehandeling)
  &.behandeling-zk
    +span-columns($columnBehandeling-zk)
  &.farmacon-ge
    +span-columns($columnFarmaconGE)
  &.patient-ge
    +span-columns($columnPatientGE)
  &.radiofarmacon
    +span-columns($columnRadiofarmacon)
  &.dosis
    +span-columns($columnDosis)
  &.onderzoeksmoment
    +span-columns($columnOnderzoeksmoment)
  &.status
    +span-columns($columnStatus)
    text-transform: capitalize
  &.apotheek
    +span-columns($columnApotheek)

  //Organisatie columns
  &.organisatie
    +span-columns($columnOrganisatie)
  &.adres
    +span-columns($columnAdres)
  &.plaats
    +span-columns($columnPlaats)
  &.voorkeur
    +span-columns($columnVoorkeursApotheek)
  &.telefoonnummer
    +span-columns($columnTelefoonNr)

  //Organisatie columns
  &.voornaam
    +span-columns($columnVoornaam)
  &.achternaam
    +span-columns($columnAchternaam)
  &.email
    +span-columns($columnEmail)
  &.functie
    +span-columns($columnFunctie)
  &.postcode
    +span-columns($columnPostcode)

