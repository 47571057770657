.status-box
  +span-columns(12)
  text-align: center
  min-height: 6em
  margin-bottom: 1em
  .status-title
    font-size: 2em
    text-align: center
    color: darken($lichtgrijs, 10%)

.loader-box
  +span-columns(12)
  margin-top: 1em
  min-height: 8em
  margin-bottom: 2em
  text-align: center
  .loader
    opacity: 0
    transition: .4s ease opacity
    &.loading
      transition: .4s ease opacity
      opacity: .8
    img
      height: 8em