#werklijst-overzicht
  .ziekenhuis-producten
    // Div om de ziekenhuizen heen
    box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.3) inset


    li, .column
      // Regels en columns van ziekenhuizen en producten
      height: 30px
      line-height: 30px

    > li
      // Dit is een ziekenhuis

    .producten
      // Div om de producten heen (binnen een ziekenhuis)

      li
        // Product

  .list > li
    // Totaal overzicht / list footer
    // border-top: 1px solid #aaa
    background-color: #666
    color: #fff

    &.geen-resultaten, &.laad-regel
      background-color: white
      color: $bodyTextColor


