// Z-index layer levels

$bottom: 0
$low: 10
$medium: 50
$high: 75
$upper: 100
$super: 999

//algemeen

$borderRadiusStandard: 3px
$standardBorder: 1px

//forms
$inputHeight: 2.8em
$paddingLeftRightInput: 1em
$sectionMargin: 0.8em

//header
$titleBarHeight: 54px

$toolbarHeight: 30px
$filterbarHeight: 50px
$filterbarBorder: 5px
$tabBarHeight: 45px
$filtersHeight: 50px
$headerHeight: $titleBarHeight+$filterbarHeight+$filterbarBorder+20px+$filtersHeight
$paddingToolbar: 0.5em
//absolute top
$filterbarTop: $titleBarHeight + $tabBarHeight
$filtersTop: $filterbarTop+ $filtersHeight

//footer
$footerHeight: $toolbarHeight + 10px

//filters
$selectedFilterHeight: 1em

//besteloverzicht
$bestelregelHoogte: 54px
$iconSize: 1.8rem
$iconColor: darken($background, 10%)

//column sizes
$columnDefault: 1
$smallColumn: 0.5
$mediumColumn: 1
$largeColumn: 1.5
$extraLargeColumn: 2
$extraExtraLargeColumn: 3
$xl4Column: 4
$xl10Column: 10
$xl11Column: 11

//besteloverzicht -- column sizes
$columnPatientNr: 0.9
$columnFarmaconGE: 1.95
$columnPatientGE: 1.55
$columnGewicht: 0.6
$columnBehandeling: 1.3
$columnBehandeling-zk: 1.8
$columnRadiofarmacon: 2.0
$columnDosis: 1
$columnOnderzoeksmoment: 0.9
$columnStatus: 1.3
$columnIcon: 0.5
$columnApotheek: 1.4
//organisatiebeheer -- column sizes
$columnOrganisatie: 2
$columnAdres: 2
$columnPostcode: 1
$columnPlaats: 2
$columnVoorkeursApotheek: 2
$columnTelefoonNr: 2

//gebruikerbeheer -- column sizes
$columnVoornaam: 2
$columnAchternaam: 2
$columnEmail: 3
$columnFunctie: 2

//NEAT Media settings
$mobile: new-breakpoint(max-width 568px 6)
$normal-desktop: new-breakpoint(1024px)
$compensateFilterBarWidth: new-breakpoint(max-width 1105px)

//toaster
$fontSize: 20px
$toastPositionFullWidthTop: 0
$toastPositionFullWidthBottom: 0

$toastPossitionTop: 12px
$toastPossitionLeft: 12px
$toastPossitionRight: 12px
$toastPossitionBottom: 12px

//calendar
$calendarRadius: 1px
