.error-log
  border-radius: $borderRadiusStandard
  list-style-type: none
  border: 1px solid $grijs
  padding: 1em
  padding-left: 2em
  max-height: 15em
  overflow-x: scroll
  +span-columns(12)
  .nr
    +span-columns(1)
  .description
    +span-columns(11)