.print-artsenverklaring
  background: none
  &#artsenverklaring
    input
      font-size: 1em
      font-style: italic
      background-color: transparent !important
      border: $standardBorder solid $grijs
      border-radius: $borderRadiusStandard
      line-height: 1.8em
      cursor: default
      &.inactive, &.ng-inactive, &[disabled]
        border: none
        border-radius: 0
        border-bottom: 1px solid $grijs
    .modal-window
      height: auto

      form
        position: relative
        top: inherit
        bottom: inherit
        left: inherit
        right: inherit
        .rijksoverheid
          +span-columns(11)

          .logo
            background: url("../images/rijksoverheid.png") no-repeat
            background-size: contain
            height: 15em
            +span-columns(1.5)
            +shift(5)

          .text
            +span-columns(5)

            color: $zwart
            margin-top: 10em
            h1
              font-size: 1.8em
            h2
              font-size: 1em
              font-style: italic

        .summary
          +shift(5.5)
          +span-columns(5)
          line-height: 1.2
          h1
            +shift(0)
          p
            font-size: 1em

        .body
          h1
            margin-bottom: 1em
          .row
            line-height: 1.1
            margin-bottom: 1em
        section

          font-size: 1.1em
          color: $zwart
          h1
            +shift(3.7)
            font-size: 1.3em
          +pad(3+em)
          .row
            +span-columns(11)
            label
              text-align: left
              line-height: 1.8em
              +span-columns(4)
            input
              +span-columns(5)
              outline: none
              &.breed-veld
                +span-columns(8)

            &.double
              +shift(3.65)
              +span-columns(8)
              .first, .second
                +span-columns(6)

              label
                +span-columns(6)

              input
                +span-columns(12)

            &.handtekening-datum
              > label
                +span-columns(4)

              .handtekening
                +span-columns(3.5)
                label
                  +span-columns(0)
                  +omega()
                input
                  +span-columns(12)
              .datum
                +span-columns(4)
                +omega
                label
                  +span-columns(12)
                  +omega()
                input
                  +span-columns(12)
                  +omega
            &.postcode-plaats
              > label
                +span-columns(4)

              .first
                +span-columns(3.5)
                label
                  +span-columns(0)
                  +omega()
                input
                  +span-columns(12)
              .second
                +span-columns(4)
                +omega
                label
                  +span-columns(12)
                  +omega()
                input
                  +span-columns(12)
                  +omega

            &.text
              +shift(3.7)
              +span-columns(8)
              p
                font-size: 1em
