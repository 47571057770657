.preset-panel
  position: absolute
  left: 0
  overflow: hidden
  max-height: 0em
  transition: 0.15s ease
  background: white
  z-index: $super
  +box-shadow

  &.datumselectie
    position: relative
    overflow: inherit
